import { DatePipe } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { LocalDataSource } from 'ng2-smart-table';
import { DoseRecordModel } from '../../../@core/models/doseRecord';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { BaseComponent } from '../base/base-component';

@Component({
  selector: 'ngx-unreturned-dosimeters',
  templateUrl: './unreturned-dosimeters.component.html',
  styleUrls: ['./unreturned-dosimeters.component.scss']
})
export class UnreturnedDosimetersComponent extends BaseComponent implements OnInit, AfterViewInit {
 @Input ("suborg") suborg;
 @Input ("org") org

  source: LocalDataSource = new LocalDataSource();
  unreturnedDosiSettings= {};
  tableForExport = [];
  unreturnedDosiColumns= {
   
    firstName: {
      title: this.ls.strings.firstName,
      type: "string",
    },
    lastName: {
      title: this.ls.strings.lastName,
      type: "string"
    },
    dosimeterId: {
      title: this.ls.strings.dosimeterId,
      type: "string"
    },
    monitoring_type: {
      title: this.ls.strings.monitoring_type,
      type: "string"
    },
    allocStart: {
      title: this.ls.strings.allocStart,
      type: Date,
      valuePrepareFunction: (d) => {
        return this.datePipe.transform(new Date(d), 'dd.MM.yyyy');
      }
    },
   frequency: {
      title: this.ls.strings.menu_monitoring_frequency,
      type: "string"
    },
    
    client: {
      title: this.ls.strings.denumire,
      type: "string"
    },
    department: {
      title: this.ls.strings.department,
      type: "string"
    },
  };

  unreturnedDefaultExcelColumns = {
    firstName: {
      title: this.ls.strings.first_name,
      type: "string",
    },
    lastName: {
      title: this.ls.strings.last_name,
      type: "string",
    },
    dosimeterId: {
      title: this.ls.strings.dosimeter_id,
      type: "string"
    },
    monitoring_type: {
      title: this.ls.strings.menu_monitoring_types,
      type: "string"
    },
    client: {
      title: this.ls.strings.denumire,
      type: "string"
    },
    department: {
      title: this.ls.strings.department,
      type: "string"
    },
    allocStart: {
      title: this.ls.strings.allocStart,
      valuePrepareFunction: (d) => {
        return this.utilsService.getFormattedDate(d, 'dd-MM-yyyy')
      },
      type: 'date',
    },
    frequency: {
      title: this.ls.strings.menu_monitoring_frequency,
      type: "string"
    },
  };

  settings= {
    actions:false,
    columns: this.unreturnedDosiColumns
  };

  constructor(public data:DataService, public auth:AuthService, public toastrService: NbToastrService, public ls:LanguageService, 
    private utilsService:UtilsService, private datePipe:DatePipe,protected changeDetectorRef:ChangeDetectorRef,protected ref: NbDialogRef<UnreturnedDosimetersComponent>) {
    super(data, auth, toastrService, ls, changeDetectorRef);
  }
  ngAfterViewInit(): void {
    //this.reinit(null);
  }

 async initializeColumns() {
  this.unreturnedDefaultExcelColumns=await this.utilsService.getDefaultColumns(this.unreturnedDosiColumns, "unreturned_dosimeters_excel_export_columns")
 }

 protected reinit(ob){
    this.loadData();
    this.ls.mySubject.subscribe(message => {
      
      setTimeout(() => {
        this.settings = {
          actions: false, 
              columns: {
   
                firstName: {
                  title: this.ls.strings.firstName,
                  type: "string",
                },
                lastName: {
                  title: this.ls.strings.lastName,
                  type: "string"
                },
                dosimeterId: {
                  title: this.ls.strings.dosimeterId,
                  type: "string"
                },
                monitoring_type: {
                  title: this.ls.strings.monitoring_type,
                  type: "string"
                },
                allocStart: {
                  title: this.ls.strings.allocStart,
                  type: Date,
                  valuePrepareFunction: (d) => {
                    return this.datePipe.transform(new Date(d), 'dd.MM.yyyy');
                  }
                },
                frequency: {
                  title: this.ls.strings.menu_monitoring_frequency,
                  type: "string"
                },
                client: {
                  title: this.ls.strings.denumire,
                  type: "string"
                },
                department: {
                  title: this.ls.strings.department,
                  type: "string"
                },
              }
            };
          
      }, 100); 
  });
  };

  private async loadData() {
    // let dr= { "query": { "query": { "suborganizationId": this.suborg, late: true} , limit:0, skip:0, 
    // "collection_name": "dose_record"}};
    let query= { "query": { "query": { late: true, status: "shipped", active: true} , limit:0, skip:0, 
    "collection_name": "dose_record"}};
  
    if (this.suborg) {
      query.query.query["suborganizationId"] = this.suborg;
    } else if (this.org) {
      query.query.query["organizationId"] = this.org;
    }
      let _doses=await this.data.getGenericAllocs(this.auth.user.token, query)
      console.log("DOSES ARE ", _doses)
      if (_doses.size>0) {
        this.source=_doses.docs;
        this.tableForExport = JSON.parse(JSON.stringify(_doses.docs));
      }
  };

  dismiss() {
    this.ref.close();
  }

  async exportXLSXUnreturnedDosimeters() {
    let columns=[]
    for (var k in this.unreturnedDefaultExcelColumns) {
      columns.push(k)
    };
    let pdata = JSON.parse(JSON.stringify(this.tableForExport));
    console.log("PDATA IS ", pdata, columns)
    this.utilsService.exportUnreturnedDosiAsExcel("UnreturnedDosimetersExport"+Date.now(), pdata, columns);
  }
}
