import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService } from '@nebular/theme';
import { LanguageService } from '../language/language.service';
import { UtilsService } from '../utils/utils.service';

@Injectable()
export class AuthGuard implements CanActivate {
  protected position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  constructor(private auth: AuthService, public toastrService: NbToastrService, private ls:LanguageService, private utils:UtilsService) {   }   

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
    if (this.auth.user!=undefined&&this.auth.user.username.length>1) {
      let component_title = next.data.component;
      let _cacc=this.auth.canAccess(component_title, "read");
      console.log("_cacc ", _cacc,"xxx ", next.data.component)
      if (component_title=="component_title_history") {
        switch (next.params.dashboard) {
          case "dosemetersDashboard": component_title="component_title_history1"; break;
          case "personDose": component_title="component_title_history2"; break;
          case "detailsForInvoicing": component_title="component_title_history3"; break;
        }
      }
      if (_cacc==true) return true;
   

      console.log("NEXT COMP BEFORE IF ", next)
      if (next.component !=undefined) {
         let _key = component_title;
        if (_key!=undefined) {
          let acc=this.auth.canAccess(_key, "read")

          if (acc==false) { this.showDenyToast();return false } else { return true; }
        } else {
          this.showDenyToast();
          return false;
        }
      }
      return true;
    } else {
      // Send guarded route to redirect to after logging in
      this.showDenyToast();
      console.log("AUTH GUARD")
      this.auth.logout();
      return false;
    }
  }
  private showDenyToast() {
    this.showToast('warning', this.ls.strings.inssuficient_rights, null)
  }
  private showToast(type: NbComponentStatus, title: string, body: string,buttonText?: string, buttonCallback?:()=>void, duration: number =3000) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 3000,
      hasIcon: true,
      position: this.position,
      //preventDuplicates: true,
    };
    const titleContent = title ? ` ${title}` : "";
    try {
    let toast=this.toastrService.show(body, `${titleContent}`, config);
    } catch(e) {
      console.log("catch for ", e)
    }
  }

}