import { OverlayKeyboardDispatcher } from "@angular/cdk/overlay";
import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { NbComponentStatus, NbDialogService, NbGlobalPhysicalPosition, NbGlobalPosition, NbToastrService  } from "@nebular/theme";
import { LocalDataSource } from "ng2-smart-table";
import { DynamicDosimetersDataComponent } from "../../../pages/admin/dynamic-dosimeters-data/dynamic-dosimeters-data.component";
import { ManageDosimetersDataComponent } from "../../../pages/admin/modals/manage-dosimeters-data/manage-dosimeters-data.component";
import { BaseComponent } from "../../../pages/client/base/base-component";
import { ConfirmationComponent } from "../../../pages/client/modals/confirmation/confirmation.component";
import { Address } from "../../models/address.model";
import { DosimeterMake } from "../../models/dosimeterMake.model";
import { DosimeterMeasurement } from "../../models/dosimeterMeasurement.model";
import { DosimeterType } from "../../models/dosimeterType.model";
import { OrganizationModel } from "../../models/organization.model";
import { PersonModel } from "../../models/person.model";
import { Representative } from "../../models/representative.model";
import { Service } from "../../models/service.model";
import { AlfrescoService } from "../../services/alfresco/alfresco.service";
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';
import { DynamicformComponent } from "../dynamicform/dynamicform.component";
import { FolderBroswerComponent } from "../folder-broswer/folder-broswer.component";
import { ELK } from "../../services/data/data.conf";
import { EphemeralKeyInfo } from "tls";
import { DomSanitizer } from "@angular/platform-browser";
import { DatePipe } from "@angular/common";
import { UnreturnedDosimetersComponent } from "../../../pages/client/unreturned-dosimeters/unreturned-dosimeters.component";
import { UtilsService } from "../../services/utils/utils.service";

@Component ({
    selector: 'ngx-client-overview',
    templateUrl: './client-overview.component.html',
    styleUrls: ['./client-overview.component.scss'],

})

export class ClientOverviewComponent extends BaseComponent implements OnInit, AfterViewInit {
@ViewChild("browser") folderBrowser:FolderBroswerComponent;
      pageData: any; 
    position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
    settings= {};
    public elk=ELK;
    autocompleteValues:any;
    dosimeterMeasurements = new DosimeterMeasurement();
    selectedDosimeterObject:DosimeterMake;
    disableUpdateButton: boolean=true;
    lateDosimeters:number=-1;
    url: any;
    source: LocalDataSource = new LocalDataSource();
    organization:OrganizationModel=new OrganizationModel();
    person:PersonModel=new PersonModel();
    address:Address=new Address();
    workplace_address:string;
    shipping_address:string;
    billing_address:string;
    representative:string;
    lang: any;
    // columns:any = {
    //   service: {
    //     title: this.ls.strings.service_type,
    //     type: "string",
    //   },
    //    price: {
    //     title: this.ls.strings.service_price,
    //     type: "string",
    //   },
    //   descriere: {
    //     title: this.ls.strings.description,
    //     type: "string"
    //   }
    //   }
    public elk_url: any;
    constructor(public ls:LanguageService, 
        private dialogService:NbDialogService, private alfrescoService:AlfrescoService, 
        public dataService:DataService, private router:Router, private route:ActivatedRoute, public auth: AuthService, 
        private sanitizer: DomSanitizer,
        private datePipe:DatePipe,
        private utils:UtilsService,
        public toastrService: NbToastrService){
        super(dataService, auth, toastrService);
        // this.title_key="component_title_clients";
        this.title_key="component_title_client_overview";
        ClientOverviewComponent.componentStaticKey=this.title_key;
    }
    protected reinit(ob): void {
        console.log("reinit this component")
        this.url = this.router.url;
        this.pageData = this.url.split("/").pop();
        this.ls.mySubject.subscribe(message => {
        this.settings = { columns:{} } 
         this.lang=this.ls.lang;
            setTimeout(() => {
                this.initialize();
                
              }, 100);
        })
        this.init();
        this.initialize();
    }

    async init(){
      this.elk_url=this.dataService.ELK.dashboard
      this.elk_url=this.sanitizer.bypassSecurityTrustResourceUrl(this.elk_url); //  this.sanitizer.bypassSecurityTrustUrl(this.elk_url);
      console.log("_________________SAFE: ", this.elk_url)
        // this.columns = {
        //     measurement: {
        //       title: this.ls.strings.service_type,
        //       type: "string",
        //     },
        //      price: {
        //       title: this.ls.strings.service_price,
        //       type: "string",
        //     },
        //     description: {
        //       title: this.ls.strings.description,
        //       type: "string"
        //     }
        //   }
        // this.settings  = Object.assign({},  {
        //     mode:'external',
        //     actions:{
        //       columnTitle:this.ls.strings.actions,
        //     },
        //     add: {
        //       addButtonContent: '<i class="nb-plus"></i>',
        //       createButtonContent: '<i class="nb-plus"></i>',
        //       cancelButtonContent: '<i class="nb-close"></i>',
        //       confirmCreate: true,
        //     },
        //     edit: {
        //       editButtonContent: '<i class="nb-edit"></i>',
        //       saveButtonContent: '<i class="nb-edit"></i>',
        //       cancelButtonContent: '<i class="nb-close"></i>',
        //       confirmSave: true,
        //       enable: false
        //     },
        //     delete: {
        //       deleteButtonContent: '<i class="nb-trash"></i>',
        //       confirmDelete: true,
        //     },
        //     columns: this.columns
        //   } );
    }


    ngAfterViewInit(): void {

    }

    private async initialize() {
      if (this.auth.user.preffered_organization==undefined) { return }
      console.log("preff in client overview", this.auth.user.preffered_organization)
        let r:any = await this.dataService.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
        if (r.docs.length>0) {
            this.organization=r.docs[0];
        }
        console.log("overviewing org: ", this.organization)
        this.workplace_address=await this.getAddress("workplace_address");
        this.billing_address=await this.getAddress("billing_address");
        this.shipping_address=await this.getAddress("shipping_address");
        this.representative=this.getRepresentative();
        this.updateLateDosimeters()
        try {
          this.source.load(this.organization.services)
        }
        catch(e) {
          this.source.load([]);
        }
    }

    async getPersons():Promise<any> {
      let resp = await this.dataService.getOrganizatonPersons(
        this.auth.user.preffered_organization,this.auth.user.token, true);
      return resp.persons; 
    }

    jumpToClientOrganization(ev) {
      this.router.navigateByUrl('pages/client/organization-profile');
    }

    getRepresentative() {
        let s="";
        try {
        s= this.organization.representative.nume+" ("+this.organization.representative.rol_reprezentant+")"
        } catch(e) {
            s="-"
        }
        return s;
    }
    async getAddress(which) {
        let addr:Address=this.organization[which]

        let s="";
        let lined=await this.utils.getAddressLineConfig();
        let addy=new Address();
        addy=addy.fromJSON(this.organization[which]);
        s = addy.getFullAddress(lined);
        return s;
        
    }

    onFirstSubmit() {

    }

    onSecondSubmit() {

    }

    openConfirmationDialog() {

    }

  private getRowData(ob, nr_crt, fields, separator, _style):string {
    let s="";//"<"+separator+">"+nr_crt+"</"+separator+">"
    for (var i=0;i<fields.length;i++) {
      let _f=fields[i]
      let _val;
      if (ob[_f]!=undefined) { _val=ob[_f]; } else { _val=""}
      console.log(i,_f,_val)
      s=s+"<"+separator+" style='"+_style+"'>"+_val.toString()+"</"+separator+">";
    }
    return s;
  }
  private servicesAsTableData():string {
    let s="";
    let _style='border: 1px solid #00000a; padding-top: 0in; padding-bottom: 0in; padding-left: 0.08in; padding-right: 0.08in';
    for (var i=0;i<this.organization.services.length;i++) {
      this.organization.services[i].service.price=this.organization.services[i].price;
      s=s+="<tr>"+this.getRowData(this.organization.services[i].service,i+1,["value", "radiation_types", "import_fields", "price"], "td", _style)+"</tr>";
    }
    return s;
  };

  async show_unreturned_dosi() {
    this.dialogService.open(UnreturnedDosimetersComponent, {
      context: {
        org: this.auth.user.preffered_organization
      
      },
      hasBackdrop: true,
      closeOnBackdropClick: false
    })
    .onClose.subscribe(async result => {
      if(result){
      }
    })
  };

  async updateLateDosimeters () {
    let res = await this.data.countCollection({ coll_name: "dose_record", "query" : { organizationId: this.auth.user.preffered_organization, late: true, status: "shipped", active: true}}, this.auth.user.token);
    this.lateDosimeters = res.number;
    console.log("LATEE is ", this.lateDosimeters)
  };

  private async wearesAsTableData():Promise<string> {
  let wearers=await this.getPersons();
let w="";
let _style='border: 1px solid #00000a; padding-top: 0in; padding-bottom: 0in; padding-left: 0.08in; padding-right: 0.08in';
var mtypes={};
for (var i=0; i<wearers.length; i++) {
  for (var k in wearers[i].monitoring_type) {
    let m=wearers[i].monitoring_type[k].monitoring_type;
    console.log("k ", k, m);
    if (mtypes[m]==undefined) { mtypes[m]={ title: m, value:0}};
    mtypes[m].value++;
  }
 
}
  var ind=0;
for (var j in mtypes) {
 
  ind++;
  w=w+="<tr>"+this.getRowData(mtypes[j],ind,[ "title", "value"], "td", _style)+"</tr>";
}
return w;

  }

  public async generateContract() {
    let b=await this.canWrite();
    if (!b) return
    let _t=await this.alfrescoService.getDocTemplate("template_contract", this.auth.user.token);
    let element: any = _t;
    let template="";
    let ob:any={};
    ob.tagDescriptors={};
    ob.inputFields=element.tags.split(",");
    ob.description=element.description;
    ob.noderef=element.noderef;
    
    console.log("this.organization is ", this.organization)
    
    let obj:any={};
    obj.denumire=this.organization.denumire;
    obj.iban=this.organization.iban;
    let addr=this.organization.workplace_address;
    obj.adresa=addr.street+", "+addr.city+", "+addr.county+", "+addr.country+" "+addr.zipCode;
    obj.cui=this.organization.cui;
    obj.cod_postal=this.organization.codPostal;
    obj.nr_reg_com=this.organization.nrRegCom;
    let reps=this.organization.representative;
    obj.telefon=reps.telefon;
    obj.fax=reps.fax;
    obj.email=reps.email;
    obj.adresa_web=this.organization.adresa_web;
    obj.banca=this.organization.banca;
    obj.monitoring_service=this.person.monitoring_type;
    obj.save_path='documentLibrary/clients'+this.organization.denumire+"/Contracts";
    //obj.savename="Contract_"+this.datePipe.transform(_date, 'dd-MM-yyyy')+this.organization.denumire;
    ob.autocompleteValues=obj
    console.log("value IS", ob)
    ob.template=this.processTemplate(template, element.tags);
    this.dialogService.open(DynamicformComponent, {
      context: {
      _object: ob
      
        },
        hasBackdrop: true,
        closeOnBackdropClick: false
      })
      .onClose.subscribe(async result => {
        if(result){
         
          let _date=new Date();
          // here we call the alfrescoService quickGenerator
          result.noderef='a949678d-ec7e-466b-9f54-f9fde3e02095';
          result.save_path='documentLibrary/clients/'+this.organization.denumire+"/Contracts";
          result.contracted_services=this.servicesAsTableData();
          result.monitoring_service=await this.wearesAsTableData();
          result.savename="Contract_"+this.organization.denumire+"_"+this.datePipe.transform(_date, 'dd-MM-yyyy');
          let resp=await this.alfrescoService.quickGenerator(this.auth.user.token, {"payload":result});
          console.log("async wait resp is ", resp);
          this.folderBrowser.refresh();
        }
      })
  }

  public async generateInvoice() {
    
  }
  private processTemplate(t: string, inputFields: string[]): string {
    return t;
  }
  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 3000,
      hasIcon: true,
      position: this.position,
      preventDuplicates: true,
    };
    const titleContent = title ? ` ${title}` : "";

    this.toastrService.show(body, `${titleContent}`, config);
    console.log(title);
  }
}