import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Console } from 'console';
import { BaseComponent } from '../../../pages/client/base/base-component';
import { DosimeterMake } from '../../models/dosimeterMake.model';
import { DosimeterType } from '../../models/dosimeterType.model';
import { AuthService } from '../../services/auth/auth.service';
import { DataService } from '../../services/data/data.service';
import { LanguageService } from '../../services/language/language.service';

@Component({
  selector: 'ngx-editwearermonitoringtype',
  templateUrl: './editwearermonitoringtype.component.html',
  styleUrls: ['./editwearermonitoringtype.component.scss']
})
export class EditwearermonitoringtypeComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {
  public buttonText = this.ls.strings.change;
  public profile: any;
  public editMode=false;
  public selectedTypes:any[];
  public _mtIndex: any;

  @Input() monitoringTypes: any[];
  @Input() dosimeterMake: any[];
  @Input() organizationId: any[];
  @Input() dosimeterType: any[];
  @Input() dosimeterPosition: any[];
  @Input() suborganizations: any[];
  @Input() monitoringType: any[];
  @Input() monitoringFequency: any[];
  @Input() active: boolean;
  @Input() wizzardMode:boolean=false;
  @Input() bulkMonitoringProfile:any;
  @Input() selectedSuborganizationId:any;
  @Input() addDefaultDepartment: any;
  @Input() disableSuborganizations: boolean = false;
  @Input() showOrangeSpan: boolean = true;
  @Input() showDom: boolean = true
  @ViewChild('dosimeterTypesSelect') dosimeterTypesSelect;
  @ViewChild('monitoringTypesSelect') monitoringTypesSelect;
  @ViewChild('suborganizationSelect') suborganizationSelect;
  @ViewChild('dosimeterMakesSelect') dosimeterMakesSelect;
  @ViewChild('monitoringFrequencySelect') monitoringFrequencySelect;
  selectedDosimeterMake:DosimeterMake;
  selectedDosimeterType:any;
  selectedDate: Date
  static SelectedDateValue:Date;
  editEnabled = false;
  buttonDisabled = false;
  initialDate:any="";

  constructor(public ls: LanguageService, public data: DataService, public auth: AuthService,
    protected ref: NbDialogRef<EditwearermonitoringtypeComponent>) {
    super(data, auth);
    this.selectedDosimeterMake = new DosimeterMake();
    this.selectedTypes=[];
    //this.selectedDosimeterMake.types = [];
    this.editEnabled = true;

  }
  ngAfterViewInit(): void {
    if (this.editMode==true) this.initialize();
   
  }
  editDetails() {
 

  }
  protected reinit(ob: any): void {
    console.log("++++++++++++++", this.wizzardMode, this.selectedSuborganizationId)
    this.profile = {};
    this.profile.active= true;
    this.profile.organizationId= "";
    this.profile.position = "";
    this.profile.monitoring_frequency = "";
    this.profile.monitoring_type = "";
    this.profile.dosimeter_make = "";
    this.profile.dosimeter_type = "";
    this.profile.dom = new Date();
    this.initialDate=this.profile.dom;
    this.profile.monitoring_frequency = "";
    this.profile.mt="";
    if (this.addDefaultDepartment!=undefined) {
      setTimeout(()=>{ 
        this.suborganizationSelect.selected=this.suborganizations.filter(elem=>elem._id==this.addDefaultDepartment)[0].denumire;
        this.profile.suborganization=this.suborganizations.filter(elem=>elem._id==this.addDefaultDepartment)[0].denumire;
        this.profile.organizationId=this.addDefaultDepartment;
        this.profile.suborganizationId=this.addDefaultDepartment;
        this.profile._sid = this.addDefaultDepartment;
      }, 50)
    }
  
    //this.initialize();
    if (this.editMode==true) this.initialize();
  }

  ngOnInit(): void {
    
    if (this.wizzardMode && this.bulkMonitoringProfile!=null) {
      this.profile=this.bulkMonitoringProfile;

    }
    if (this.profile!=null) {
      this.editMode=true;
      this.initialDate=this.profile.dom;
      
    } else {
      this.reinit(null);
      this.editMode=false;
    }
  }

  async initialize() {
    console.log("++++++++++++++", this.wizzardMode, "profile is ", this.profile, this.selectedSuborganizationId, "profile orgId is ", 
    this.auth.user.preffered_organization, this.profile.organizationId)
    try {
    this.suborganizationSelect.selected=this.suborganizations.filter(elem=>elem._id==this.profile.organizationId)[0].denumire;
    this.profile.organization=this.suborganizations.filter(elem=>elem._id==this.profile.organizationId)[0].denumire;
    } catch(e) { }
    
    if (this.wizzardMode==true && this.selectedSuborganizationId!=undefined) {
      console.log("IN HERE WITH WIZARD")
      this.suborganizationSelect.selected=this.suborganizations.filter(elem=>elem._id==this.selectedSuborganizationId)[0].denumire;
      this.profile.suborganization=this.suborganizations.filter(elem=>elem._id==this.selectedSuborganizationId)[0].denumire;
      this.profile.organizationId=this.selectedSuborganizationId;
      this.profile.suborganizationId=this.selectedSuborganizationId;
      this.profile._sid = this.selectedSuborganizationId;
    }
    try {

   this.selectedDosimeterMake=this.dosimeterMake.filter(elem=>elem['value']==this.profile.dosimeter_make)[0];
   let _d=this.selectedDosimeterMake.types.filter(elem=>elem['value']==this.profile.dosimeter_type)[0];

   this.selectedDosimeterType=_d;
   this.dosimeterTypesSelect.selected=_d;
   let _calc;
   console.log("MONITORING TYPES are ", this.monitoringTypes)
   try { _calc=this.monitoringTypes.filter(elem=>elem['description']==this.profile.mt.description && elem['key']==this.profile.mt.key); } catch(e) { _calc=[];}

   if (this.profile.mt!=undefined && _calc.length>0) {
    console.log("MONITORING TYPES  ", this.profile.mt, _calc.length, _calc)

   this.mtSelected(this.monitoringTypes.indexOf(_calc[0]));
   } else {
    console.log("in else @188", this.monitoringTypes, this.profile.monitoring_type, this.profile)
   this.mtSelected(this.monitoringTypes.indexOf(this.monitoringTypes.filter(
    elem=>elem['measurement']==this.profile.monitoring_type
    && elem['description']==this.profile.description
  )[0]));
   }
    } catch(e) {
      console.log("catched? ", e)
      if (this.selectedDosimeterMake==undefined) {
        this.selectedDosimeterMake=new DosimeterMake();
        this.selectedDosimeterType=null;
        this.dosimeterTypesSelect.selected=null;
        console.log("------------------SELECTED MAKE ", this.selectedDosimeterMake)
      }
      
    }
    
  }

  ngAfterViewChecked(): void {
    //this.changeDetector.detectChanges();
  }
  dismiss() {
    this.ref.close();
  }
 
//   invalidated(type:any):boolean {
//     let d = false;
//     if (type=="dom") {
//       if ((this.profile[type] && this.profile[type].length<1) || !this.profile[type]) { d=true; }
//     return d
   
//   }
// }

mtSelected(event) {
console.log("mtSelected? ", event)
console.log("PROFILE ", this.profile, "MonitoringType are ", this.monitoringTypes)
this._mtIndex = event;
let selectedMonitoringType=this.monitoringTypes[event];

if (selectedMonitoringType) {
  this.profile.monitoring_type = selectedMonitoringType.measurement;
  console.log("this.profile.moni is ", this.profile.monitoring_type)
  this.profile.mt = selectedMonitoringType;
  console.log("selected moni type are ", selectedMonitoringType);
this.profile.description = selectedMonitoringType.description;
console.log("this.profile.desc is ", this.profile.description)
  //take the key from services.service.key, not from service key
  this.profile.mt.key = selectedMonitoringType.service.key;
  console.log("measurements is ", this.profile.monitoring_type, "desc is ", this.profile.description, "MT is ", this.profile.mt);
} else {
  this.profile.active = false;

}
 console.log("measurements is ", this.profile.monitoring_type, "desc is ", this.profile.description, "MT is ", this.profile.mt)
}


addBulkMonitoring() {
  console.log("Profile is ", this.profile, this.bulkMonitoringProfile);
  //this.addMonitoringType();
}

  updateMonitoringType() {
    console.log("profile is ", this.profile);
    if (this.initialDate!=this.profile.dom) { this.profile.allocEnd=this.profile.dom; }
    this.ref.close(this.profile);
  }
  addMonitoringType() {
    console.log("Profile is ", this.profile)
    this.profile.allocEnd=this.profile.dom;
    this.ref.close(this.profile)
  }

  onChangeEvent(event) {
    this.buttonDisabled=true;
  }
  selectionPositionChange(event) {

  }

  onDateChangeEvent(event) {
    console.log("Date event is ", event)
this.selectedDate=event.srcElement.value;
EditwearermonitoringtypeComponent.SelectedDateValue=this.selectedDate;
  }

  onChange(event) {

  }

  selectionMonitoringChange(event) {
    console.log(event);
    let d=this.dosimeterMake.filter(elem=>elem['value']==event)[0];
    console.log("d is ", d);
    this.dosimeterTypesSelect.selected=null;
    if (d.types && d.types.length>0) {
    this.selectedDosimeterMake=d;
    } else {

    }
  }

  selectionOrganizationChange(event) {
    console.log("event is ", event)
    let o=this.suborganizations.filter(elem=>elem['denumire']==event)[0];
    console.log("o is ", o);
    // this.profile.organizationId=o._id;

    //CHANGING SUBORGANIZATION IN MT
    this.profile.suborganizationId = o._id;
    this.profile.suborganization =o.denumire;
    this.profile.suborganization_code = o.organization_code;
    this.profile._sid = o._id;
    console.log("this profile after update is ", this.profile)
  }



  selectionMonitoringTypeChange(event) {
    console.log("Event is ", event);
    this.profile.dosimeter_type=event.value;
  }
  selectionFrequencyChange(event) {
    
  }

  disabledButton(): Boolean {
    let bool = false;
    try {
    if(this.selectedDate!=null && this.monitoringTypesSelect.selected!=null && this.dosimeterTypesSelect.selected!=null && this.dosimeterTypesSelect.selected!=null && this.suborganizationSelect.selected!=null && this.monitoringFrequencySelect.selected!=null) bool=true;
    } catch(e) {}
    return bool;
  }

}
