import { Component, OnDestroy, OnInit,AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService, NbToastrService } from '@nebular/theme';

//import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '../../../@core/services/language/language.service';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { SearchComponent } from '../../../pages/admin/search/search.component';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseComponent } from '../../../pages/client/base/base-component';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy,AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  newNotifications:number=0;
  isSystemUser:boolean=false;
  adminLogged: boolean =false;
  showAllLanguages: boolean;
  public header_installed_languages: any[] = [];
  themes = [
 
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
   
   
  ];

  currentTheme = 'corporate';

  @ViewChild('organizationsSelect') organizationsSelect;
  @ViewChild('suborganizationSelect') suborganizationSelect;
  @ViewChild('languageSelectorDropDown') languageSelectorDropDown;
  parentOrganizations=[];
  suborganizations =[];
  denumire=[];
  language:any;

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              public data: DataService,
              public toastrService: NbToastrService,
              protected changeDetector: ChangeDetectorRef,
              public ls:LanguageService,public auth:AuthService,
              private cdRef: ChangeDetectorRef, private utils: UtilsService,
              private router:Router,
              private route:ActivatedRoute,
            ) {
          
          super(data, auth,toastrService, ls, changeDetector );    
          this.initialize();
  }


  private initialize() {

  /* 
    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
       */

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
      this.menuService.onItemClick()
      .pipe(takeUntil(this.destroy$))
      .subscribe((event) => {
        
        if (event.item.title === this.ls.strings.logout && this.auth.user.token!="") {
          console.log("header component")
         this.auth.logout()
        }
      });
  }


  protected  reinit(ob){
    this.configShowLanguages()
    this.isSystemUser=this.auth.isSystemRole();
    this.currentTheme = 'corporate';
 
    
    this.header_installed_languages=[];
    for (var k in this.ls.installed_languages) {
      let ln=this.ls.installed_languages[k];
        this.header_installed_languages.push(ln)
     
    }
    console.log("ls.lang are ", this.ls.lang, "installed lang are ", this.ls.installed_languages, this.header_installed_languages)
    try {
      this.languageSelectorDropDown.selected=this.ls.lang;
    } catch(e) {}
      

    //this.installed_languages=this.ls.installed_languages;
    if (this.auth.user.roles==undefined) return;
    let checkRoleExistence = roleParam => this.auth.user.roles.some( role => role == roleParam)

    // if(checkRoleExistence("superadmin") || checkRoleExistence("admin")){
    //   this.adminLogged = true;
    //    this.getParentOrganizations();
    // }
    if(this.isSystemUser==true) {
      this.adminLogged = true;
      this.getParentOrganizations();
    }
    else {
     
      setTimeout(() => {
        this.getParentOrganizations();
      }, 1000);
     
      this.getUserDetails()
    }


  }

  async getParentOrganizations(){
    this.parentOrganizations = [];
   //let r = await this.data.getParentOrganizations(this.auth.user.token);
   let r = await this.utils.getOrganizationNames();
    r = r.filter(el=>el.parent == '');
   
    if(r.length>0){
      console.log('ORGANIZATIONS TO DISPLAY IN HEADERS SELECT', r.length)
      this.parentOrganizations = r;
      if(this.auth.user.preffered_organization==undefined) this.auth.user.preffered_organization = r[0]._id;
      if(this.organizationsSelect!==undefined) {
        this.organizationsSelect.selected = this.auth.user.preffered_organization; 
        console.log("MMMMMMS",this.organizationsSelect)
      }
    } 
  
   
    else {
      this.sidebarService.collapse('menu-sidebar');
      this.layoutService.changeLayoutSize();
    }
  };

 
  async selectionChange(event){ 
     this.auth.user.preffered_organization = event;
     this.auth.user.selected_suborganization = null;
    await this.auth._updateUserFromLocalStorage()
    this.data.setObjectData(event)
  };

  refresh() {
    location.reload()
  }
  

  ngOnDestroy() {
  
    this.destroy$.next();
    this.destroy$.complete();
    
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
 ngAfterViewInit(){
   
 }
 async getNotificationsCount() {
  let r=await this.data.countCollection({ coll_name: "notification", query: { recipientId: this.user._id, new: true}}, this.auth.user.token) 
  if (r.status==200) {
    this.newNotifications=r.number;
  }
 };

 async getUserDetails(){
  let r = await this.data.getUserDetails(this.auth.user.token, this.auth.user.username, true, true);
  this.user = r;
  if (this.auth.user._id==null || String(this.auth.user._id)=="undefined" || this.auth.user._id=="") { this.auth.user._id=r._id;}
  this.suborganizations=[];
  for (var k in this.user.organizations) {
    this.suborganizations.push(this.user.organizations[k]);
  };
   this.getSuborgNameById()
   if (this.auth.user) this.getNotificationsCount();
};

async getSuborganizations(){
  let resp = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);
  if(resp.status!=404 && resp.length>0){
    this.suborganizations=resp.filter(el=>this.user.organizations.indexOf(el._id)!=-1)
    if (this.suborganizations.length>0) {
    this.suborganizationSelect.selected=this.suborganizations[0]._id;
    this.auth.user.selected_suborganization=this.suborganizations[0]._id;
    }
   
  } 
};

languageSelectorSelect(event: string) {
  console.log("language selector event", event)
  this.ls.changeLanguage(event);
  this.header_installed_languages=[];
  console.log("this.ls.installed ", this.ls.installed_languages)
  for (var k in this.ls.installed_languages) {
    let ln=this.ls.installed_languages[k];
      this.header_installed_languages.push(ln)
  }
 
};

async getSuborgNameById() {
  let resp = await this.data.getSuborgNameById(this.auth.user.token, this.user, true);
  this.denumire =[];
  for (var k in resp.organizations.docs){
    this.denumire.push(resp.organizations.docs[k].denumire)
  };

};

searchButton() {
  this.router.navigateByUrl('pages/admin/admin-search');
};

async configShowLanguages() {
  this.showAllLanguages=await this.utils.getShowAllLanguages();
  console.log("config multiple_languages_switch is ", this.showAllLanguages);
 };

}
