import { Injectable } from '@angular/core';
import { UserModel } from '../../models/user.model'
import { Router } from '@angular/router';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
 
import { DataService } from '../data/data.service';
import 'rxjs/add/observable/of';
import 'rxjs/add/observable/timer';
import 'rxjs/add/operator/mergeMap';
import { ToastService } from '../toast/toast.service';
import {
  NbComponentStatus,
  NbGlobalLogicalPosition,
  NbGlobalPhysicalPosition,
  NbGlobalPosition,
  NbToastrService,
  NbToastrConfig,
} from '@nebular/theme';
import { LanguageService } from '../language/language.service';
import { RoleModel } from '../../models/roleModel';
import { UtilsService } from '../utils/utils.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user:UserModel;
  
  config: NbToastrConfig;
  organizationNames:any[];
  index = 1;
  destroyByClick = true;
  duration = 2000;
  hasIcon = true;
  position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
  preventDuplicates = false;
  status: NbComponentStatus = 'primary';
  loggedIn: boolean=false;
  loggedIn$ = new BehaviorSubject<boolean>(this.loggedIn);
  refreshSub: Subscription;
  private _expiresAt=600;
  public accessComponents:any;
  public static instance:AuthService;
  private licenseObj;
  private authRoles:RoleModel[];

  constructor(private router: Router, private data:DataService,private ls:LanguageService,private toastService:ToastService,  
    public toastrService: NbToastrService) { 
    //
    if (AuthService.instance==null) {
      AuthService.instance=this;
      AuthService.instance.accessComponents=[];
      
    }
    this.registerComponents();
    if (this.tokenValid) {
      //console.log(this.user)
      console.log(this.tokenValid)
      this.performInit();
    } else if (!this.tokenValid && this.user) {
      console.log("Auth service token invalid")
      this.logout();
    }
  }

 async performInit() {
  
    let rsp = await this._retrieveUserFromLocalStorage();
  this._setSession(this.user)
  this.setLoggedIn(true);
  this.scheduleRenewal();
 // await this.getRoles();
 }

 async getRoles() {
  try{
    let resp = await this.data.getUserRoles(this.user.token);
    this.authRoles=resp.docs;
    console.log("this.authRoles are ", this.authRoles)
  } catch(e) {console.log("ERROR")}
 
  
 }

  async setLoggedIn(value: boolean) {
    // Update login status subject

    this.loggedIn$.next(value);
    this.loggedIn = value;

  }
  get tokenValid(): boolean {
    // Check if current time is past access token's expiration
     return true;
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return Date.now() < expiresAt;
  }
  async login(redirect?: string, _message?:string) {
    // Set redirect after login
    //console.log("redirect is ", redirect);
   console.log(redirect)
    if (redirect!="") {
      const _redirect = redirect ? redirect : 'login';
      localStorage.setItem('authRedirect', _redirect);
    }
    let usr:any=localStorage.getItem("usr");
    if (usr) {
      this.performLogin(this.data);
    ;
      
    } else {
  
    }
  }
  public setLicenseObject(obj) {
    this.licenseObj=obj;
    console.log("RECEIVED LICENSE OBJECT ", obj)
  }
  invalidateToken() {
    this.logout();
    setTimeout(() => {
      this.showToast( 'warning', this.ls.strings.authorization_expired, '')
   }, 100);
  }
  async logout(noRedirect?: boolean) {
    console.log("in logout???? ", this.router.url)

    // Ensure all auth items removed from localStorage
    this._clearUserFromLocalStorage();
    //this.user=new UserModel();
    this._clearRedirect();
    // Reset local properties, update loggedIn$ stream
 
    this.setLoggedIn(false);
    // Unschedule access token renewal
    this.unscheduleRenewal();
    // Return to homepage\
  
    this.router.navigateByUrl('/auth/login');
  
    if (noRedirect !== true) {
    
      this.router.navigateByUrl('/auth/login');
     
    }
    setTimeout(() => {
      // this.showToast( 'success',this.ls.strings.logout_success, '')
    }, 200);
    console.log("should signout |", this.user.token.length, this.user.token,"|")
    if (this.user.token!="") {
      let _t=this.user.token.split("").join("");
      this.user=new UserModel();
      await this.data.signout(_t);
    }
  }
  public async performMFALogin(data) {
    let resp=await this.data.postMFASignIn(data);
    console.log("mfa resp is ", resp)
    return resp;
  
  }
  public async performLogin(data) {
    console.log("DATA ISS ", data)
    let resp=await this.data.postSignIn(data);
    console.log("RRRRRRRRRRRRRRRRrrr",resp)
     console.log(resp.profile)

     console.log('post sign in', resp)
    
    if (resp.token) {
    //  this.showSuccess('Autentificare facuta cu succes');
      this.user=new UserModel(); 
      this.user.profile = resp['profile'];
      this.user.username=resp.profile.username;
      this.user.firstName=resp.profile.firstName;
      this.user.lastName=resp.profile.lastName;
      this.user.roles=resp.profile.roles;
    
      console.log("|||PROFILE|||",this.user.profile)
      this.user.organizations=resp['profile']['organizations'];
  
      console.log("LOGIN ORGANIZATION NAMES IS ", this.organizationNames);
      if(this.user.preffered_organization==''||this.user.preffered_organization==undefined){
        this.user.preffered_organization=this.user.organizations[0]
      } else this.user.preffered_organization=resp['profile']['preffered_organization'][0]
      this.user.token = resp.token; 
      await this.getRoles();
  setTimeout(() => {
     this.showToast( 'success', this.ls.strings.login_success, '')
  }, 100);
      console.log("perform login user profile is ", this.user.profile)
    
      await this._updateUserFromLocalStorage();
         
      this._setSession(this.user);

      let checkRoleExistence = roleParam => this.user.roles.some( role => role == roleParam)
      if (this.user.profile.organizations.length==0) {
        // trebuie facut o componenta unde sa il reroutam de utilizator
        // folosim, ca mai jos this.router.navigateByUrl
        this.router.navigateByUrl('/pages/client/organization-profile');
        
      }
      if(checkRoleExistence("superadmin") || checkRoleExistence("admin")){
         this.router.navigateByUrl('/pages/admin/clients')
      }
      else if (this.isSystemRole()) {
        this.router.navigateByUrl('/pages/client/user-profile')
      }
      else {
        console.log("on if?")
        this.router.navigateByUrl('/pages/client/client-dashboard')
      }
      
 //location.reload();
    }
    else {
      if (resp.status === 403) {
          this.showToast('danger', this.ls.strings.contact_admin, ''); 
      } else if (resp.code) {
          this.showToast('danger', this.ls.strings[resp.code], '');
      } else {
          this.showToast('danger', this.ls.strings.incorrect_credentials, '');
          let ob={ message: "login_error", icon: "error", color:"red"}
      }
  }
  }

  public isAdmin() {
    let bool=false;
    if (this.user.roles==undefined) { console.log("auth service canAccess isAdmin");  this.logout();return; }
    if(this.user.roles.indexOf("superadmin",0)>-1 || this.user.roles.indexOf("admin",0)>-1) bool=true;
    return bool
  };
 
   public async _retrieveUserFromLocalStorage() {
    let lsProfile = localStorage.getItem('profile');
    let username = localStorage.getItem('username');
    let roles = localStorage.getItem('roles')
     const token =  localStorage.getItem("token");
     let preffered_organization  = localStorage.getItem('preffered_organization')
     let organizations=localStorage.getItem('organizations');
    
     console.log(organizations)
      
    if (lsProfile) {
      this.user=new UserModel();
      this.user.token = token;
      this.user.firstName=lsProfile['firstName'];
      this.user.lastName=lsProfile['lastName']
      if(preffered_organization!=undefined){
        console.log('pref', preffered_organization)
        try {
          this.user.preffered_organization=JSON.parse(preffered_organization)
        }
        catch(e){
         
        }
        
      }
    this.user.username=JSON.parse(username);
    try {this.user.roles=JSON.parse(roles);} catch(e){}
    try {this.user.organizations=JSON.parse(lsProfile).organizations;} catch(e){}
      if(this.user.preffered_organization==''||this.user.preffered_organization==undefined){
        this.user.preffered_organization=this.user.organizations[0]
      }
       this.user.setProfile(JSON.parse(lsProfile))
       console.log("|||||",this.user)
   
    } else {
      this.user=new UserModel();
      this.user.token = token;
      console.log("token", token)
      if (token) {
      lsProfile = await this.data.getUserProfile(token);
      
      console.log("new profile is ", lsProfile)
      try {
      this.user.setProfile(lsProfile);
      this._updateUserFromLocalStorage();
      } catch (e) { }
     }
    }
  }

  private _clearUserFromLocalStorage() {
    localStorage.removeItem('token');
      localStorage.removeItem('profile');
      localStorage.removeItem("id_contract")
      localStorage.clear();
    }
    private _clearRedirect() {
      localStorage.removeItem('authRedirect');
    }

    unscheduleRenewal() {
      if (this.refreshSub) {
        this.refreshSub.unsubscribe();
      }
    }
 //save local UserModel
 public _updateUserFromLocalStorage() {
 
  const expiresAt = JSON.stringify((this._expiresAt * 1000) + Date.now());
 
  localStorage.setItem('token', this.user.token);
   localStorage.setItem('profile', JSON.stringify(this.user.profile));
   localStorage.setItem('username', JSON.stringify(this.user.username));
   localStorage.setItem('roles',JSON.stringify(this.user.roles));

   localStorage.setItem('preffered_organization',JSON.stringify(this.user.preffered_organization));
   localStorage.setItem('organizations',JSON.stringify(this.user.organizations));
  
 }
    private _setSession(usr:UserModel) {
      this._updateUserFromLocalStorage()
      // Update login status in loggedIn$ stream
      this.setLoggedIn(true);
      // Schedule access token renewal
      this.scheduleRenewal();
    }

    private async renewToken() {
      let rsp:any
      if (rsp==false ) {
        this.logout(true);
        this.login("","login_expired");
      } else {
        this._updateUserFromLocalStorage();
        this.scheduleRenewal();
      }
    }
    private async scheduleRenewal() {
      // If user isn't authenticated, do nothing
      if (!this.tokenValid) { return; }
      // Unsubscribe from previous expiration observable
      this.unscheduleRenewal();
      // Create and subscribe to expiration observable
      const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
      const expiresIn$ = Observable.of(expiresAt)
        .mergeMap(
          expires => {
            const now = Date.now();
            const tmr = Math.max(1, expires - now)
            // Use timer to track delay until expiration
            // to run the refresh at the proper time
            return Observable.timer(tmr);
          }
        );
      this.refreshSub = expiresIn$
        .subscribe(
          () => {
            this.renewToken();
          }
        );
    }


    
  showSuccess(message) {
    this.toastService.show(message, {
      classname: 'btn-primary btn btn d-block align-bottom',
      delay: 5000 ,
      autohide: true,
       headertext: 'Succes'
    }); 
  }
  showError(message) {
    this.toastService.show(message, {
      classname: 'btn-danger btn d-block ',
      delay: 5000 ,
      autohide: true,
      headertext: 'Eroare!'
    });
  }

  public showToast(type: NbComponentStatus, title: string, body: string) {
    const config = {
      status: type,
      destroyByClick: true,
      duration: 6000,
      hasIcon: true,
      position: this.position,
      preventDuplicates:true
  
    };
    const titleContent = title ? ` ${title}` : '';
  
   
    this.toastrService.show(
      body,
      `${titleContent}`,
      config);
      console.log(title)
  }
  private registerComponents() {
    //console.log("----------------------------authService registering components", LanguageService.initialized);
    if (LanguageService.initialized==true) {
      for (var k in this.ls.strings) {
        if (k.indexOf("component_title_",0)!=-1 || k.indexOf("menu_",0)!=-1) {
          let r:any=[ {key:"read", value:false}, {key: "edit", value: false}, {key:"write", value:false}, {key: "delete", value:false} ];
          AuthService.instance.accessComponents.push({ name:k, rights: r});
          
        }
      }
      console.log(AuthService.instance.accessComponents);
      console.log("We have "+ AuthService.instance.accessComponents.length+" components to watch out for!")
    } else {
      console.log("LS not ready");
      setTimeout( ()=>{
        this.registerComponents();
      }, 50)
      
    }
  }
  public static registerComponent(comp:any) {
    return 
    if (AuthService.instance.accessComponents==null) { AuthService.instance.accessComponents=[]; }
    //console.log("AuthService.instance.accesComponent ---------------------- ", typeof(comp)) ;//,  AuthService.instance.accessComponents.length);
    if (AuthService.instance.accessComponents.indexOf(comp)==-1) {
      //AuthService.instance.accessComponents.push(comp);

      
    }
    console.log("AuthService.instance.accesComponent ---------------------- ", comp,  AuthService.instance.accessComponents.length);
  }
  public hasAdminRole(role):Boolean {
    let b=false;
    if (this.user.roles.indexOf("admin",0)>-1) { b=true;}
    return b;
  };

  public isSuperadminRole(){
    let b=false;
    if (this.user.roles.indexOf("superadmin",0)>-1) { b=true;}
    return b;
  };

  public hasClientAdminRole():Boolean {
    let b=false;
    if (this.user.roles.indexOf("client",0)>-1) { b=true;}
    return b;
  };
  public isSystemRole() {
   // console.log("this user is ", this.user, "role is ", this.user.roles)
    if (!this.user || !this.user.roles || this.user.roles.length === 0) {
      return false; 
    }
    if (this.user.roles.indexOf("superadmin",0)>-1) return true;
    for (var k in this.user.roles) {
      let r=this.authRoles.filter(el=>el.name==this.user.roles[k])[0];
      // console.log("r is", r)
      if (r.system_user==true) {
        return true; 
      }
    }
    return false;
  };

  public getAuthRoles() {
    return this.authRoles;
  };


  public canAccess(comp_title_key:string, access:string, menucheck?:boolean):Boolean {
    
    if (this.user.roles!=undefined && this.user.roles.indexOf("superadmin",0)>-1)  
    return true;
    if (this.user.roles==undefined) { 
      console.log("auth service canAccess 1  |", comp_title_key,"|", access, menucheck); 
      if (menucheck!=true) { 
        //console.log("auth service canAccess", menucheck, this.router.url)
        this.logout(); }
      return false
    }
    if (this.authRoles==undefined) {  
      console.log("auth service canAccess 2 ", menucheck, this.router.url); 
      if (menucheck!=true)  this.logout(); 
      return false
    }
    let b=false;
    for (var k in this.user.roles) {
      let _r=this.user.roles[k];
      let _ur:RoleModel;
      try {
        _ur=this.authRoles.filter((role:RoleModel) => { return role.name == _r; })[0];
      } catch(e) { _ur=null}
      if (_ur) {
        //console.log("_ur   ", _ur)
        let _ac:any=_ur.accessList.filter((ob:any)=> { return ob.name === comp_title_key;})[0];
        if (_ac) { 
          let _right=_ac.rights.filter((_right:any)=>  { return _right.key == access})[0];
          b=_right.value;
          //return _right.value;
          if (b==true) return b;
        }
      }
    }
    return b;
  };



  canAccessByPath(normalizedMenu, pth):Boolean {
    let b=false;
    let menuLinks=normalizedMenu;
    console.log("menu Links", menuLinks)
    return b;
  }

  public isSuperAdmin(){
    let bool=false;
    if (this.user.username=='superadmin' || this.user.roles.indexOf("superadmin",0)>-1) bool=true;
    return bool;
  };
  // public isSuperAdmin():Boolean {
  //   let bool=false;
  //   if (this.user.username=='superadmin') bool=true;
  //   return bool;
  // };

  public canAccess_old(usr:UserModel):Boolean {
    let b=false;
    if (usr.roles.indexOf("administrator",0)>-1 || usr.username=='admin') { 
      return true
    }
    return b;
  }
}
